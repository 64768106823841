var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"},attrs:{"id":"selections"}},[_c('div',{attrs:{"id":"count"}},[_c('span',[_vm._v(" 您已选择了"+_vm._s(this.selectedMustList.length)+"个必选项目，"+_vm._s(this.selectedRestrictedList.length)+"个七选五项目， "+_vm._s(this.selectedOptionalList.length)+"个任选项目，"+_vm._s(this.selectedParttimeList.length)+"个非全日制学生参加项目。 ")])]),_c('div',{staticClass:"selection"},[_c('el-transfer',{staticStyle:{"text-align":"left","display":"inline-block"},attrs:{"filterable":"","titles":['未选项目：必选', '已选项目：必选'],"button-texts":['取消选项', '选择该项'],"format":{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      },"data":_vm.MustList},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return _c('span',{},[_vm._v(_vm._s(option.key)+" - "+_vm._s(option.label))])}}]),model:{value:(_vm.selectedMustList),callback:function ($$v) {_vm.selectedMustList=$$v},expression:"selectedMustList"}},[_c('el-tooltip',{staticClass:"item",attrs:{"slot":"left-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"left-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeMust}},[_vm._v("撤销选择")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"slot":"right-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"right-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeMust}},[_vm._v("撤销选择")])],1)],1)],1),_c('div',{staticClass:"selection"},[_c('el-transfer',{staticStyle:{"text-align":"left","display":"inline-block"},attrs:{"filterable":"","titles":['未选项目：七选五', '已选项目：七选五'],"button-texts":['取消选项', '选择该项'],"format":{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      },"data":_vm.RestrictedList},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return _c('span',{},[_vm._v(_vm._s(option.key)+" - "+_vm._s(option.label))])}}]),model:{value:(_vm.selectedRestrictedList),callback:function ($$v) {_vm.selectedRestrictedList=$$v},expression:"selectedRestrictedList"}},[_c('el-tooltip',{staticClass:"item",attrs:{"slot":"left-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"left-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeRestricted}},[_vm._v("撤销选择")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"slot":"right-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"right-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeRestricted}},[_vm._v("撤销选择")])],1)],1)],1),_c('div',{staticClass:"selection"},[_c('el-transfer',{staticStyle:{"text-align":"left","display":"inline-block"},attrs:{"filterable":"","titles":['未选项目：任选', '已选项目：任选'],"button-texts":['取消选项', '选择该项'],"format":{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      },"data":_vm.OptionalList},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return _c('span',{},[_vm._v(_vm._s(option.key)+" - "+_vm._s(option.label))])}}]),model:{value:(_vm.selectedOptionalList),callback:function ($$v) {_vm.selectedOptionalList=$$v},expression:"selectedOptionalList"}},[_c('el-tooltip',{staticClass:"item",attrs:{"slot":"left-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"left-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeOptional}},[_vm._v("撤销选择")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"slot":"right-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"right-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeOptional}},[_vm._v("撤销选择")])],1)],1)],1),_c('div',{staticClass:"selection"},[_c('el-transfer',{staticStyle:{"text-align":"left","display":"inline-block"},attrs:{"filterable":"","titles":['未选项目：非全日制参加', '已选项目：非全日制参加'],"button-texts":['取消选项', '选择该项'],"format":{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      },"data":_vm.ParttimeList},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return _c('span',{},[_vm._v(_vm._s(option.key)+" - "+_vm._s(option.label))])}}]),model:{value:(_vm.selectedParttimeList),callback:function ($$v) {_vm.selectedParttimeList=$$v},expression:"selectedParttimeList"}},[_c('el-tooltip',{staticClass:"item",attrs:{"slot":"left-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"left-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeParttime}},[_vm._v("撤销选择")])],1),_c('el-tooltip',{staticClass:"item",attrs:{"slot":"right-footer","effect":"dark","content":"此操作将撤销您上次提交以来，对项目选择的修改。","placement":"bottom"},slot:"right-footer"},[_c('el-button',{staticClass:"transfer-footer",attrs:{"size":"small"},on:{"click":_vm.revokeParttime}},[_vm._v("撤销选择")])],1)],1)],1),_c('div',{attrs:{"id":"submit-button"}},[_c('el-button',{staticClass:"selection-submit",attrs:{"round":"","type":"primary","disabled":this.selectable!==1},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(this.submitText)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }