<template>
  <div id="selections" style="text-align: center">
    <div id="count">
      <span>
        您已选择了{{this.selectedMustList.length}}个必选项目，{{this.selectedRestrictedList.length}}个七选五项目，
        {{this.selectedOptionalList.length}}个任选项目，{{this.selectedParttimeList.length}}个非全日制学生参加项目。
      </span> 
    </div>
    <div class="selection">
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="selectedMustList"
        filterable
        :titles="['未选项目：必选', '已选项目：必选']"
        :button-texts="['取消选项', '选择该项']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        :data="MustList"
      >
        <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span>
        <el-tooltip class="item" effect="dark" slot="left-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeMust"
          >撤销选择</el-button
        ></el-tooltip>
        <el-tooltip class="item" effect="dark" slot="right-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeMust"
          >撤销选择</el-button
        ></el-tooltip>
      </el-transfer>
    </div>
    <div class="selection">
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="selectedRestrictedList"
        filterable
        :titles="['未选项目：七选五', '已选项目：七选五']"
        :button-texts="['取消选项', '选择该项']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        :data="RestrictedList"
      >
        <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span>
        <el-tooltip class="item" effect="dark" slot="left-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeRestricted"
          >撤销选择</el-button
        ></el-tooltip>
        <el-tooltip class="item" effect="dark" slot="right-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeRestricted"
          >撤销选择</el-button
        ></el-tooltip>
      </el-transfer>
    </div>
    <div class="selection">
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="selectedOptionalList"
        filterable
        :titles="['未选项目：任选', '已选项目：任选']"
        :button-texts="['取消选项', '选择该项']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        :data="OptionalList"
      >
        <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span>
        <el-tooltip class="item" effect="dark" slot="left-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeOptional"
          >撤销选择</el-button
        ></el-tooltip>
        <el-tooltip class="item" effect="dark" slot="right-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeOptional"
          >撤销选择</el-button
        ></el-tooltip>
      </el-transfer>
    </div>
    <div class="selection">
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="selectedParttimeList"
        filterable
        :titles="['未选项目：非全日制参加', '已选项目：非全日制参加']"
        :button-texts="['取消选项', '选择该项']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        :data="ParttimeList"
      >
        <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span>
        <el-tooltip class="item" effect="dark" slot="left-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeParttime"
          >撤销选择</el-button
        ></el-tooltip>
        <el-tooltip class="item" effect="dark" slot="right-footer" content="此操作将撤销您上次提交以来，对项目选择的修改。" placement="bottom">
        <el-button class="transfer-footer" size="small" @click="revokeParttime"
          >撤销选择</el-button
        ></el-tooltip>
      </el-transfer>
    </div>
    <div id="submit-button">
      <el-button class="selection-submit" round type="primary" @click="submit" :disabled="this.selectable!==1">
        {{this.submitText}}
      </el-button>
    </div>
  </div>
</template>

<style>
.selection {
  height: 360px;
}
#selections #count {
  font-size: large;
  color: darkslateblue;
  width: auto;
  margin-bottom: 20px;
}
.el-transfer-panel {
  width: 250px;
  /* height: 400px; */
}
.el-button [class*=el-icon-]+span {
    margin-left: 0px;
}
.el-transfer__buttons .el-transfer__button {
    display: flex;
}
.el-button+.el-button {
    margin-left: 0px;
}
.el-transfer__button:first-child {
    margin-bottom: 20px;
}
.selection-submit {
    font-size: large;
    margin-top: 20px;
    margin-bottom: 70px;
}
#submit-button .el-button.is-round {
    padding: 17px 30px;
}
</style>

<script>
import axios from 'axios';
import qs from 'qs';
import mixin from "../../mixins/universalmixins.js";
export default {
    name: "SelectGame",
    mixins: [mixin],
    data() {
        return {
            // in for initial, in开头的是页面获取时最初的列表
            inSelectedMustList: [],
            inSelectedRestrictedList: [],
            inSelectedOptionalList: [],
            inSelectedParttimeList: [],
            // 以下为未选的和已选的列表
            MustList: [],
            RestrictedList: [],
            OptionalList: [],
            ParttimeList: [],
            selectedMustList: [],
            selectedRestrictedList: [],
            selectedOptionalList: [],
            selectedParttimeList: [],
            selectable: 0, // 目前是否可以选项，0尚未开始，1正在进行，2已结束
            submitText: "选项未开始",
            renderFunc(h, option) {
                return <span>{ option.key } - { option.label }</span>;
            }
        };
    },
    methods: {
        // handleChange() {
        //     // todo
        // },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        obj2ObjList(gameListObj, must=0) {
            var gameListObject = Object(gameListObj);
            var gameList = [];
            for (var gameid in gameListObject) {
                if (gameid != undefined) {
                  if(!isNaN(Number(gameid))) {
                    gameList.push({
                            key: Number(gameid),
                            label: String(gameListObject[gameid]),
                            disabled: ((this.selectable===1 && must==0)? false: true),
                        });
                  }
                }
            }
            return gameList;
        },
        obj2NumList(gameListObj) {
            var gameList = [];
            for (var gameid in gameListObj) {
                if (gameid != undefined) {
                    gameList.push(Number(gameid));
                }
            }
            return gameList;
        },
        revokeMust() {
            this.selectedMustList = this.inSelectedMustList;
        },
        revokeRestricted() {
            this.selectedRestrictedList = this.inSelectedRestrictedList;
        },
        revokeOptional() {
            this.selectedOptionalList = this.inSelectedOptionalList;
        },
        revokeParttime() {
          this.selectedParttimeList = this.inSelectedParttimeList;
        },
        submit() {
            axios({
              url: "/api/dept/select/update",
              method: "POST",
              headers: {
                Authorization: localStorage.getItem("token"),
                "content-type": "application/x-www-form-urlencoded",
              },
              data: qs.stringify({
                id: this.userinfo.userbelong,
                mustselect: JSON.stringify(this.selectedMustList),
                restrictedselect: JSON.stringify(this.selectedRestrictedList),
                optionalselect: JSON.stringify(this.selectedOptionalList),
                parttimeselect: JSON.stringify(this.selectedParttimeList)
              })
            })
            .then(async ()=>{
                // console.log(res);
                await this.messageBox("提交选项成功！", "success");
                await this.sleep(250);
                this.$router.go(0);
            })
            .catch((err) => {
                this.messageBox(
                  "提交选项失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                  "error"
                );
            console.log("Update game selection info Failed");
            console.log(err.response);
            });
        }
    },
    async mounted() {
        // wait for userinfo to get userid
        let i = 0;
        while ((this.userinfo == null || this.yearinfo == null) && i < 40) {
          i++;
          await this.sleep(250);
        }
        if (this.yearinfo != null) {
            if (this.yearinfo.selectstatus != undefined) {
                this.selectable = Number(this.yearinfo.selectstatus);
                if (this.selectable === 1) {
                    this.submitText = "提交选项";
                } 
                else if (this.selectable === 2) {
                    this.submitText = "选项已结束";
                }
            } else {
                this.selectable = 0;
            }
        } else {
            this.messageBox("获取选项状态失败，请检查网络连接或重新登录。", 'error');
        }
        if (this.userinfo.userbelong != undefined) {
            // get selected games
            axios({
              url: "/api/dept/select/get",
              method: "GET",
              headers: {
                Authorization: localStorage.getItem("token"),
              },
              params: {
                id: this.userinfo.userbelong,
                select: 1,
              }
            })
            .then((res)=>{
                // console.log(res.data);
                this.inSelectedMustList = this.obj2NumList(res.data.mustselect);
                this.inSelectedRestrictedList = this.obj2NumList(res.data.restrictedselect);
                this.inSelectedOptionalList = this.obj2NumList(res.data.optionalselect);
                this.inSelectedParttimeList = this.obj2NumList(res.data.parttimeselect);
                this.selectedMustList = this.obj2NumList(res.data.mustselect);
                this.selectedRestrictedList = this.obj2NumList(res.data.restrictedselect);
                this.selectedOptionalList = this.obj2NumList(res.data.optionalselect);
                this.selectedParttimeList = this.obj2NumList(res.data.parttimeselect);
                this.MustList = this.obj2ObjList(res.data.mustselect, 1);
                this.RestrictedList = this.obj2ObjList(res.data.restrictedselect);
                this.OptionalList = this.obj2ObjList(res.data.optionalselect);
                this.ParttimeList = this.obj2ObjList(res.data.parttimeselect);
                // get unselected games
                axios({
                  url: "/api/dept/select/get",
                  method: "GET",
                  headers: {
                    Authorization: localStorage.getItem("token"),
                  },
                  params: {
                    id: this.userinfo.userbelong,
                    select: 0,
                  }
                })
                .then((res)=>{
                    // console.log(res.data);
                    this.MustList = this.MustList.concat(this.obj2ObjList(res.data.mustselect, 1));
                    this.MustList.sort(function (a, b) {
                        return a.key - b.key;
                    });
                    this.RestrictedList = this.RestrictedList.concat(this.obj2ObjList(res.data.restrictedselect));
                    this.RestrictedList.sort(function (a, b) {
                        return a.key - b.key;
                    });
                    this.OptionalList = this.OptionalList.concat(this.obj2ObjList(res.data.optionalselect));
                    this.OptionalList.sort(function (a, b) {
                        return a.key - b.key;
                    });
                    this.ParttimeList = this.ParttimeList.concat(this.obj2ObjList(res.data.parttimeselect));
                    this.ParttimeList.sort(function(a, b) {
                        return a.key - b.key;
                    })
                })
                .catch((err) => {
                    this.messageBox(
                      "获取未选项目失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                      "error"
                    );
                    console.log("Get game selection info Failed");
                    console.log(err.response);
                });
            })
            .catch((err) => {
                this.messageBox(
                  "获取已选项目失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                  "error"
                );
                console.log("Get game selection info Failed");
                console.log(err.response);
            });
            
        } else {
            this.messageBox('获取用户ID失败，请检查网络连接或重新登录。', 'error');
        }
    },
}
</script>
